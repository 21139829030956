<template>
  <div class="registration-step-1-block">
    <div class="page-title"><h2>{{ $lang.app.relationship_check }}</h2></div>
    <div v-if="result === null" class="check-block-container">
      <h4 class="hint-title">{{ $lang.app.relationship_check_instruction }}</h4>
      <div class="reg-step-form">
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childPin" type="text" autocomplete="off"
                     v-maska="'##############'"
                     v-model="checkForm.childPin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content tooltip-content-small">
                  <p>{{ $lang.app.birth_certificate_des }}</p>
                  <p>{{ $lang.app.birth_certificate_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../assets/images/birth-cert.png" alt="">
                      <span>{{ $lang.app.birth_certificate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="childPin" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.parent_pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="parentPin" type="text"
                     v-maska="'##############'"
                     v-model="checkForm.parentPin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content">
                  <p>{{ $lang.app.id_card_des }}</p>
                  <p>{{ $lang.app.id_card_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../assets/images/pin-2017.png" alt="">
                      <span>{{ $lang.app.id_card }}</span>
                    </div>
                    <div class="passport-image">
                      <img src="../assets/images/pin-2004.png" alt="">
                      <span>{{ $lang.app.passport }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="parentPin" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
        <div class="note-block">
          <span class="note-title">{{ $lang.app.note_title }}</span>
          {{ $lang.app.relationship_check_note }}
        </div>
      </div>
    </div>
    <div v-if="result !== null" class="check-results-block-container">
      <div v-if="result" class="relation-check-results">
        <i class="bi-check"></i>
        <h4>{{ $lang.app.applicant_is_parent.replace(':parent', isParentMale ? $lang.app.is_father : $lang.app.is_mother) }}</h4>
      </div>
      <div v-else class="relation-check-results result-rejected">
        <i class="bi-x-octagon"></i>
        <h4>{{ $lang.app.applicant_is_not_parent.replace(':parent', isParentMale ? $lang.app.is_father : $lang.app.is_mother) }}</h4>
      </div>
      <div class="check-results-btn"><a href @click.prevent="result = null">{{ $lang.app.back }}</a></div>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'RelationshipCheck',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  directives: { maska },
  data() {
    return {
      checkForm: {
        childPin: '',
        parentPin: '',
        recaptchaToken: null,
      },
      schema: yup.object({
        childPin: yup.string().required(),
        parentPin: yup.string().required(),
      }),
      result: null,
    }
  },
  computed: {
    isParentMale() {
      return this.checkForm.parentPin.startsWith('2')
    },
  },
  methods: {
    async submit(values, actions) {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.checkForm.recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/children/is-parent`, this.checkForm).then(({data}) => {
        this.result = data
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    }
  }
}
</script>
<style scoped>
@import '../assets/styles/form.css';
@import '../assets/styles/title.css';
@import '../assets/styles/note.css';
.check-block-container {
  width: 40%;
  margin: 0 auto;
  padding: 55px 0 45px;
  border-bottom: 1px solid #d0d0d0;
}
@media screen and (max-width: 1600px) {
  .check-block-container {
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  .check-block-container {
    width: 60%;
    padding: 40px 0 50px;
  }
}
@media screen and (max-width: 767px) {
  .check-block-container {
    width: calc(100% - 30px);
    padding: 30px 0 35px;
  }
}

.relation-check-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  color: mediumseagreen;
  max-width: 560px;
  margin: 0 auto;
  text-align: center;
}
.relation-check-results.result-rejected {
  color: orangered;
}
.relation-check-results i {
  font-size: 120px;
  display: inline-block;
  margin-bottom: 20px;
}
.relation-check-results h4 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0 0 15px 0;
  font-weight: bold;
}
.check-results-btn {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}
.check-results-btn a {
  width: 16.6666%;
  display: inline-block;
  height: 55px;
  line-height: 55px;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  padding: 0;
  transition: 0.5s;
  text-decoration: none;
  font-size: 18px;
  background: linear-gradient(to bottom, #efefef 0%, #e2e2e2 100%);
  border: 1px solid #d0d0d0;
  color: #4c9ac7;
}
@media screen and (max-width: 1600px) {
  .check-results-btn a {
    width: 25%;
  }
}
@media screen and (max-width: 1200px) {
  .relation-check-results i {
    margin-bottom: 15px;
  }
  .relation-check-results h4 {
    font-size: 18px;
  }
  .check-results-btn a {
    width: 33.3333%;
  }
}
@media screen and (max-width: 767px) {
  .relation-check-results {
    padding: 15px 0 25px;
  }
  .relation-check-results i {
    margin-bottom: 10px;
  }
  .check-results-btn {
    padding: 30px 15px;
  }
  .check-results-btn a {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .relation-check-results i {
    font-size: 32px;
  }
}
</style>